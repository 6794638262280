<template>
    <section class="pt-3">
        <div class="d-flex mx-0">
            <div class="col-auto pt-3 px-3">
                <div class="btn-back f-14" @click="$router.push({name:'admin.tesoreria.pasarela-pagos'});">
                    <i class="icon-left" />
                </div>
            </div>
            <div class="col px-0">
                <tabla-general :data="dataTable"  class-header="text-general f-16" alto="calc(100vh - 195px)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto f-600 text-general f-18">
                            Lista de cuentas de PayU
                        </div>
                        <div class="col d-middle justify-content-end">
                            <div class="btn-general px-3" @click="agregarCuenta">
                                Añadir Cuenta
                            </div>
                        </div>
                    </template>
					<el-table-column label="Nombre">
                        <template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light">{{ row.nombre }}</p>
                        </template>
                    </el-table-column>
					<el-table-column label="Cedis" width="90">
                        <template slot-scope="{row}">
							<el-tooltip effect="light" placement="bottom">
								<div slot="content">
									<div v-for="(item,index) in 2" :key="index">
										{{ 'Nombre cedis' }}
									</div>
								</div>
                            	<span class="f-16 text-dark fr-light">{{ row.cant_cedis }}</span>
							</el-tooltip>
                        </template>
                    </el-table-column>
					<el-table-column label="Descripción">
                        <template slot-scope="{row}">
                            <p class="f-16 text-dark fr-light lh-19">{{ row.descripcion }}</p>
                        </template>
                    </el-table-column>
					<el-table-column width="60">
						<template slot-scope="{row}">
							<div class="row mx-0 justify-center">
								<el-tooltip placement="bottom" :content="row.estado ? 'Inactivar' : 'Activar'" effect="light">
									<el-switch v-model="row.estado" :active-value="1" :inactive-value="0" active-color="#28D07B" inactive-color="#DBDBDB" @change="cambiarEstadoCuenta(row.id)" />
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column width="140">
						<template slot-scope="{row}">
							<div class="d-middle" style="gap: 0.75rem">
								<el-tooltip content="Eliminar cuenta" effect="light" placement="bottom">
									<div class="btn-caja cr-pointer border d-middle br-5" @click="modalEliminarCuenta(row.id)">
										<i class="icon-delete-outline f-20 text-general" />
									</div>
								</el-tooltip>
								<el-tooltip content="Editar CEDIS" effect="light" placement="bottom">
									<div class="btn-caja cr-pointer border d-middle br-5" @click="gestionarCedis(row.id)">
										<i class="icon-cedis f-20 text-general" />
									</div>
								</el-tooltip>
								<el-tooltip content="Editar cuenta" effect="light" placement="bottom">
									<div class="btn-caja cr-pointer border d-middle br-5">
										<i class="icon-pencil-outline f-20 text-general" @click="$refs.modalEditarCuenta.toggle(row)" />
									</div>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column width="60">
						<template slot-scope="{row}">
							<div class="btn-caja mx-2 cr-pointer border d-middle br-5" @click="gestionarCedis(row.id)">
								<i class="icon-cedis f-20 text-general" />
							</div>
						</template>
					</el-table-column>
					<el-table-column width="60">
						<template slot-scope="{row}">
							<div class="btn-caja mx-2 cr-pointer border d-middle br-5">
								<i class="icon-pencil-outline f-20 text-general" @click="$refs.modalEditarCuenta.toggle(row)" />
							</div>
						</template>
					</el-table-column> -->
                    <!-- <template slot="adicionales-derecha">
                        <el-table-column width="60">
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <el-tooltip placement="bottom" :content="row.estado ? 'Inactivar' : 'Activar'" effect="light">
                                        <el-switch v-model="row.estado" :active-value="1" :inactive-value="0" active-color="#28D07B" inactive-color="#DBDBDB" @change="cambiarEstadoCuenta(row.id)" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="60">
                            <template slot-scope="{row}">
                                <div class="btn-caja mx-2 cr-pointer border d-middle br-5" @click="modalEliminarCuenta(row.id)">
                                    <i class="icon-delete-outline f-20 text-general" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="60">
                            <template slot-scope="{row}">
                                <div class="btn-caja mx-2 cr-pointer border d-middle br-5" @click="gestionarCedis(row.id)">
                                    <i class="icon-cedis f-20 text-general" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="60">
                            <template slot-scope="{row}">
                                <div class="btn-caja mx-2 cr-pointer border d-middle br-5">
                                    <i class="icon-pencil-outline f-20 text-general" @click="$refs.modalEditarCuenta.toggle(row)" />
                                </div>
                            </template>
                        </el-table-column>
                    </template> -->
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-cuenta ref="modalCrearCuenta" @update="listarCuentas" />
        <modal-editar-cuenta ref="modalEditarCuenta" @update="listarCuentas" />
        <modal-gestion-cedis ref="modalGestionCedis" />
        <modal-eliminar ref="modalEliminar" titulo="Eliminar Cuenta" mensaje="¿Desea eliminar esta Cuenta?" @eliminar="eliminarCuenta" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pagos from "~/services/pasarela-tesoreria/pagos"
export default {
    components: {
        modalCrearCuenta: () => import('./partials/modalCrearCuenta'),
        modalEditarCuenta: () => import('./partials/modalEditarCuenta'),
        modalGestionCedis: () => import('./partials/modalGestionCedis')
    },
    data(){
        return {
            dataTable: [],
            dataColumns: [
                {valor: 'nombre', titulo: 'Nombre'},
                {valor: 'cant_cedis', titulo: 'Cedis', class: 'text-center',ancho:100},
                {valor: 'descripcion', titulo: 'Descripcion'}
            ],
            id_eliminar:null
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        id_cedis(val){
            this.listarCuentas()
        }
    },
    mounted(){
        if(this.id_cedis != null){
            this.listarCuentas()
        }
    },
    methods: {
        async listarCuentas(){
            try {

                const {data} =  await Pagos.listarCuentas()
                this.dataTable = data.cuentas

            } catch (e){
                this.error_catch(e)
            }
        },
        agregarCuenta(){
            this.$refs.modalCrearCuenta.toggle()
        },
        modalEliminarCuenta(id){
            this.id_eliminar = id
            this.$refs.modalEliminar.toggle();
        },
        gestionarCedis(id){
            this.$refs.modalGestionCedis.toggle(id)
        },
        async cambiarEstadoCuenta(id){
            try {

                const {data} =  await Pagos.cambiarEstadoCuenta(id)
                this.notificacion('','Estado de la cuenta actualizado','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarCuenta(){
            try {

                const {data} =  await Pagos.eliminarCuenta(this.id_eliminar)
                this.notificacion('','Cuenta Eliminada','success')
                this.listarCuentas()
                this.$refs.modalEliminar.toggle();

            } catch (e){
                this.error_catch(e)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.btn-caja{
    width: 30px;
    height: 30px;
    background-color: #FDFDFD;
}
</style>
