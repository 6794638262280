var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-3"},[_c('div',{staticClass:"d-flex mx-0"},[_c('div',{staticClass:"col-auto pt-3 px-3"},[_c('div',{staticClass:"btn-back f-14",on:{"click":function($event){return _vm.$router.push({name:'admin.tesoreria.pasarela-pagos'});}}},[_c('i',{staticClass:"icon-left"})])]),_c('div',{staticClass:"col px-0"},[_c('tabla-general',{attrs:{"data":_vm.dataTable,"class-header":"text-general f-16","alto":"calc(100vh - 195px)"}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto f-600 text-general f-18"},[_vm._v(" Lista de cuentas de PayU ")]),_c('div',{staticClass:"col d-middle justify-content-end"},[_c('div',{staticClass:"btn-general px-3",on:{"click":_vm.agregarCuenta}},[_vm._v(" Añadir Cuenta ")])])]),_c('el-table-column',{attrs:{"label":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-dark fr-light"},[_vm._v(_vm._s(row.nombre))])]}}])}),_c('el-table-column',{attrs:{"label":"Cedis","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"effect":"light","placement":"bottom"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((2),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s('Nombre cedis')+" ")])}),0),_c('span',{staticClass:"f-16 text-dark fr-light"},[_vm._v(_vm._s(row.cant_cedis))])])]}}])}),_c('el-table-column',{attrs:{"label":"Descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-dark fr-light lh-19"},[_vm._v(_vm._s(row.descripcion))])]}}])}),_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('el-tooltip',{attrs:{"placement":"bottom","content":row.estado ? 'Inactivar' : 'Activar',"effect":"light"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"active-color":"#28D07B","inactive-color":"#DBDBDB"},on:{"change":function($event){return _vm.cambiarEstadoCuenta(row.id)}},model:{value:(row.estado),callback:function ($$v) {_vm.$set(row, "estado", $$v)},expression:"row.estado"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-middle",staticStyle:{"gap":"0.75rem"}},[_c('el-tooltip',{attrs:{"content":"Eliminar cuenta","effect":"light","placement":"bottom"}},[_c('div',{staticClass:"btn-caja cr-pointer border d-middle br-5",on:{"click":function($event){return _vm.modalEliminarCuenta(row.id)}}},[_c('i',{staticClass:"icon-delete-outline f-20 text-general"})])]),_c('el-tooltip',{attrs:{"content":"Editar CEDIS","effect":"light","placement":"bottom"}},[_c('div',{staticClass:"btn-caja cr-pointer border d-middle br-5",on:{"click":function($event){return _vm.gestionarCedis(row.id)}}},[_c('i',{staticClass:"icon-cedis f-20 text-general"})])]),_c('el-tooltip',{attrs:{"content":"Editar cuenta","effect":"light","placement":"bottom"}},[_c('div',{staticClass:"btn-caja cr-pointer border d-middle br-5"},[_c('i',{staticClass:"icon-pencil-outline f-20 text-general",on:{"click":function($event){return _vm.$refs.modalEditarCuenta.toggle(row)}}})])])],1)]}}])})],2)],1)]),_c('modal-crear-cuenta',{ref:"modalCrearCuenta",on:{"update":_vm.listarCuentas}}),_c('modal-editar-cuenta',{ref:"modalEditarCuenta",on:{"update":_vm.listarCuentas}}),_c('modal-gestion-cedis',{ref:"modalGestionCedis"}),_c('modal-eliminar',{ref:"modalEliminar",attrs:{"titulo":"Eliminar Cuenta","mensaje":"¿Desea eliminar esta Cuenta?"},on:{"eliminar":_vm.eliminarCuenta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }